import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from 'react'
import CustomInput from "./CustomInput";
function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}


const sendTelegram = async (msg) => {


  const TOKEN = process.env.REACT_APP_TELEGRAME_TOKEN
  const CHAT_ID = process.env.REACT_APP_CHAT_ID
  const message = encodeURIComponent(msg)
  const sendUrl = `https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${message}`

  await fetch(sendUrl)
}

const SendForm = () => {

  const [openedNum, setOpenedNum] = React.useState(2);
  const [playlist, setPlaylist] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState(null);

  const [loading, setLoading] = React.useState(false);


  const isContent = message || playlist
  // const actNum = isContent ? 0 : openedNum

  useEffect(() => {
    setErrorMsg(null)
  }, [message, playlist])


  const handleInputClick = (num) => {
    if (openedNum === num) return
    setErrorMsg(null)
    setOpenedNum(num)
    setMessage('')
    setPlaylist('')
  }

  const handleSend = () => {
    setErrorMsg(null)
    const errorMessages = { '1': 'Please enter a valid playlist link', '2': 'Please enter your message' }
    if (!isContent) return setErrorMsg(errorMessages[openedNum])
    if (playlist && !validURL(playlist)) return setErrorMsg(errorMessages[1])

    setLoading(true)


    toast.promise(
      () => sendTelegram(playlist || message).finally(() => {
        setLoading(false)
        setMessage('')
        setPlaylist('')
        setOpenedNum(0)
      }),
      {
        pending: '🫡 Sending your message ... ',
        success: 'Thanks for sharing 👌',
        error: "Something went wrong 🤯,  please try again later  "
      },
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    )
  }


  return (
    <>
      <div className="music_form">

        {/* <CustomInput
          isOpen={openedNum === 1 || !!playlist}
          inputClicked={() => handleInputClick(1)}
          placeholder='Share your favorate playlist 🎵'
          val={playlist}
          setVal={setPlaylist}
          handleBlur={() => setPlaylist(m => m.trim())}
          disabled={loading}
        >
          <i className="fas fa-music icon"></i>
        </CustomInput> */}

        <CustomInput
          isOpen={openedNum === 2 || !!message}
          inputClicked={() => handleInputClick(2)}
          placeholder='Leave a note or a message 🤗'
          val={message}
          setVal={setMessage}
          handleBlur={() => setMessage(m => m.trim())}
          disabled={loading}
        >
          <i className="far fa-comment-alt icon"></i>
        </CustomInput>

        <button disabled={!isContent} className={`custom_send__icon ${loading ? 'loading' : ''}`} onClick={handleSend}> <i className="fas fa-paper-plane icon"></i> </button>


      </div>
      {errorMsg ? (
        <div>
          <small className="error"> {errorMsg}  </small>
        </div>
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>


  )
}

export default SendForm