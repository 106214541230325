import React from 'react'

const Social = () => {
  return (
    <ul>
      <li className="twitter">
        <a href="https://twitter.com/HossamMarey" target="_blank" rel="noreferrer"><i className="fab fa-twitter icon"></i></a>
      </li>
      <li className="github">
        <a href="https://github.com/HossamMarey" target="_blank" rel="noreferrer"><i className="fab fa-github icon"></i></a>
      </li>
      <li className="linkedin">
        <a href="https://www.linkedin.com/in/hossam-marey/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in icon"></i></a>
      </li>
      <li className="youtube">
        <a href="https://www.youtube.com/c/Codv-academy" target="_blank" rel="noreferrer"><i className="fab fa-youtube icon"></i></a>
      </li>
      {/* <li className="facebook">
        <a href="https://www.facebook.com/hossam.marey.180/" target="_blank" rel="noreferrer">
          <i className="fab fa-facebook-f icon"></i> </a>
      </li> */}
      <li className="instagram">
        <a href="https://www.instagram.com/hossam.marey/" target="_blank" rel="noreferrer"><i className="fab fa-instagram icon"></i></a>
      </li>
      <li className="contact">
        <a href="https://hossammarey.com/contact" target="_blank" rel="noreferrer"><i className="fas fa-envelope icon"></i></a>
      </li>

      {/* <li className="curiouscat" title="Curiouscat : Ask me a question ... ">
        <a href="https://curiouscat.live/HossamMarey" target="_blank" rel="noreferrer">
          <span className=" icon">

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 334 334"  >
              <g>
                <path d="M325.4,78.3c0,20.2-2.3,40.2-8.4,59.5c-1.9,6.1-1.8,11.8-0.4,18.1c7.3,31.9,5.8,63.3-6.4,94c-7.9,19.8-21.4,33.5-41.1,41.8
		c-20.5,8.7-40.6,18.4-60.9,27.6c-27.8,12.6-55.6,12.5-83.4-0.4c-18.4-8.5-36.4-18-55.4-25.1c-27.2-10.2-42.4-29.7-50.1-56.6
		c-7.7-27-8.2-54.1-1.7-81.3c1.5-6.4,1.2-12.3-0.5-18.6C5.7,97.2,5.8,56.9,17.5,16.8C20.8,5.6,28.2,2.6,38.4,8
		c29.8,15.8,54.3,37.7,75,64.1c2.9,3.7,4.8,3.8,8.9,1.8c29.8-14.8,59.8-14.8,89.6,0c4.1,2,5.9,1.8,8.8-1.8
		C241.5,45.8,266,23.7,295.9,8c10.3-5.4,17.5-2.4,21,8.8C322.4,34.5,325.3,55.5,325.4,78.3z"/>
              </g>
            </svg>
          </span></a>
      </li> */}

    </ul>
  )
}

export default Social