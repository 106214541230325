import React from "react";
const CustomInput = ({
  placeholder,
  children,
  isOpen,
  inputClicked,
  val,
  setVal,
  handleBlur,
  disabled,
}) => {
  const handleClick = (e) => {
    if (disabled) return;
    e.stopPropagation();
    inputClicked();
  };
  return (
    <div
      className={`${isOpen ? "open" : ""} custom_input `}
      onClick={handleClick}
    >
      <span className="custom_input__icon">{children}</span>
      <div className="custom_input__input">
        <input
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          value={val}
          onChange={(e) => setVal(e.target.value)}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default CustomInput;
