

import './App.css';
import SendForm from "./components/SendForm";
// import Quote from "./components/Quote";
import Social from "./components/Social";

function App() {

  return (
    <>
      {/* <Quote /> */}
      <Social />
      <SendForm />
    </>
  );
}

export default App;
